import { Order, TableOrder } from 'services/PaaServices/types'

const filterSlug = (slug: string) => slug.split('__')[0]

const filterOrder = (slug: string) => slug.split('__')[1]

const findOrder = (field: string, ordering: Order[], currentType: string) => {
  const model = (ordering.filter(order => order.model === currentType))
  if (model.length) {
    const fieldOrder = model.find(slug => filterSlug(slug.ordering) === field)
    return fieldOrder ? filterOrder(fieldOrder.ordering) as TableOrder : undefined
  }
  return undefined
}

const nextOrder = (order: string) => {
  if (order === 'asc') return 'desc'
  if (order === 'desc') return null
  return 'asc'
}

export {
  filterSlug, filterOrder, findOrder, nextOrder,
}
