/* eslint-disable max-len */
const terms = {
  Common: {
    pendingQuery: 'Requête en cours...',
    continue: 'Continuer',
    goBack: 'Retour',
    noResults: 'Pas de données',
  },
  Error: {
    invalidType: 'Seuls les formats .txt, .zip et .csv sont autorisés.',
    tooManyFiles: 'Veuillez envoyer les fichiers séparément.',
    server: 'Une erreur serveur est survenue. Veuillez réessayer. (Code: 500)',
    unknown: 'Une erreur est survenue.',
    notFound: 'Une erreur serveur est survenue. Veuillez réessayer. (Code: 404)',
    badRequest: 'Une erreur est survenue. Vérifiez les informations que vous avez entrées et réessayez.',
    conflict: 'Cet objet est en conflit. Réglez le conflit et réessayez.',
    forbidden: {
      appTitle: "Vous n'avez pas les droits nécessaires pour accéder à cette application",
      appSubtitle: 'Veuillez contacter votre administrateur',
      content: "Vous n'avez pas les droits pour accéder à cette ressource. Veuillez réessayer ou contacter votre administrateur.",
      exitButton: 'Retour au portail des applications',
    },
  },
  Import: {
    title: 'Import des fichiers',
    subtitleEmpty: 'Veuillez importer votre premier fichier.',
    subtitle: 'Cela peut prendre quelques minutes si le fichier est volumineux. Les données seront supprimés de l\'outil au bout de quelques heures.',
    dragFile: 'Glissez-déposez le fichier ici ou',
    dragFile2: 'sélectionnez le fichier sur votre ordinateur',
    fileImport: 'Imports des fichiers',
    buttonImport: 'Visualiser les fichiers importés',
    deletionSuccess: 'Fichier supprimé',
  },
  Export: {
    fileExport: 'Exporter',
    title: 'Exporter en xls',
    subtitle: 'Choisir les onglets à exporter dans le fichier',
    exportRunning: 'Export en cours...',
    exportDone: 'Export terminé',
  },
  Table: {
    addFilter: 'Ajouter un filtre',
  },
}

export default terms
