import {
  Badge, Button,
} from '@mui/material'
import terms from 'common/terms'
import { ReactElement } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setType, showExport, showImport } from 'reducers/paa'
import { ImportKind } from 'services/PaaServices/types'
import { RootState } from 'Store'
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined'
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined'
import './ToolBar.scss'

export default function ToolBar(): ReactElement {
  const dispatch = useDispatch()
  const { activeType, importList, models } = useSelector((state: RootState) => state.paa)

  const onTypeChange = (type: ImportKind) => {
    dispatch(setType(type))
  }

  return (
    <div className="toolbar py-4 px-3 bg-white">
      <div>
        <Badge
          className="file-badge"
          color="secondary"
          badgeContent={importList.length}
          sx={{
            '.MuiBadge-badge': {
              padding: '2px 6px 0px',
            },
          }}
        >
          <Button
            onClick={() => dispatch(showImport())}
            variant="outlined"
            startIcon={<CloudUploadOutlinedIcon />}
          >
            {terms.Import.fileImport}

          </Button>
        </Badge>
      </div>
      <div>
        {models.map(model => (
          <Button
            variant={model.slug === activeType ? 'selected' : 'outlined'}
            onClick={() => onTypeChange(model.slug as ImportKind)}
            key={model.slug + model.name}
            disableRipple
            className="mx-2"
          >
            {model.name}

          </Button>
        ))}
      </div>
      <div>
        <Button
          onClick={() => dispatch(showExport())}
          variant="light"
          startIcon={<FileDownloadOutlinedIcon />}
          disableRipple
        >
          {terms.Export.fileExport}

        </Button>
      </div>
    </div>
  )
}
