import {
  TableCell, TableHead, TableRow, TableSortLabel,
} from '@mui/material'
import { ReactElement, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PaaServices from 'services/PaaServices'
import { RootState } from 'Store'
import CustomMenu from './CustomMenu'
import { filterSlug, findOrder, nextOrder } from './Order'

export default function DataTableHeader(): ReactElement {
  const dispatch = useDispatch()
  const { activeType, models, ordering } = useSelector((state: RootState) => state.paa)
  const [isHovered, setHover] = useState<string>('')

  const handleMouseOver = (field: string) => {
    if (!isHovered) {
      setHover(field)
    }
  }

  const handleMouseOut = () => {
    setHover('')
  }

  const changeOrder = (field: string) => {
    const newOrder = nextOrder(findOrder(field, ordering, activeType) as string)
    if (newOrder !== null) {
      dispatch(PaaServices.sendOrdering({ model: activeType, ordering: `${field}__${newOrder}` }))
      return
    }
    // TODO replace with delete ordering
    dispatch(PaaServices.sendOrdering({ model: activeType, ordering: `${field}__asc` }))
  }

  const setMenu = (open: string | null) => {
    if (open === null) {
      setHover('')
    }
  }

  return (
    <TableHead>
      <TableRow>
        {models.find(model => model.slug === activeType)?.fields.map(field => (
          <TableCell
            key={field.slug}
            onMouseOver={() => handleMouseOver(field.slug)}
            onMouseOut={handleMouseOut}
          >
            <TableSortLabel
              active={(ordering?.find(order => filterSlug(order.ordering) === field.slug) !== undefined)}
              direction={findOrder(field.slug, ordering, activeType)}
              onClick={() => changeOrder(field.slug)}
            >
              {field.name}
            </TableSortLabel>
            <CustomMenu setMenu={setMenu} column={field.slug} visible={isHovered} />
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  )
}
