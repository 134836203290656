import { isAnyOf } from '@reduxjs/toolkit'
import PaaServices from 'services/PaaServices'

export const deleteErrorMatcher = isAnyOf(
  PaaServices.deleteImport.rejected,
)

export const deletePendingMatcher = isAnyOf(
  PaaServices.deleteImport.pending,
)
