import MoreVertIcon from '@mui/icons-material/MoreVert'
import {
  IconButton, List, ListItem, ListItemButton, ListItemText, Popover,
} from '@mui/material'
import terms from 'common/terms'
import { MouseEvent, ReactElement, useState } from 'react'
import './CustomMenu.scss'

type Props = {
  column: string;
  visible: string;
  setMenu: (e: string | null) => void;
}
export default function CustomMenu({ column, visible, setMenu }: Props): ReactElement {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setMenu(column)
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setMenu(null)
    setAnchorEl(null)
  }

  // const handleFilter = () => {
  //   handleClose()
  //   if (filters.find(filter => filter.columnField === column)) {
  //     dispatch(setFilterMenu(true))
  //     return
  //   }
  //   const filterId = filters.length ? (filters[filters.length - 1].id as number) + 1 : 0
  //   dispatch(addFilter({
  //     id: filterId,
  //     columnField: column,
  //     operatorValue: GridOperator.contains,
  //     value: '',
  //   }))
  //   dispatch(setFilterMenu(true))
  // }

  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined

  return (
    <div className={visible === column ? 'active-menu-icon' : 'inactive-menu-icon'}>
      <IconButton onClick={handleClick}>
        <MoreVertIcon />
      </IconButton>
      <Popover
        id={id}
        open={open}
        sx={{ zIndex: 1600 }}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <div className="table-menu">
          <List>
            <ListItem disablePadding>
              <ListItemButton onClick={() => null}>
                <ListItemText primary={terms.Table.addFilter} />
              </ListItemButton>
            </ListItem>
          </List>
        </div>
      </Popover>
    </div>
  )
}
