/* eslint-disable no-restricted-properties */
import CancelRoundedIcon from '@mui/icons-material/CancelRounded'
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded'
import CloseIcon from '@mui/icons-material/Close'
import { Backdrop, IconButton } from '@mui/material'
import Loader from 'components/Loader'
import { ReactElement } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PaaServices from 'services/PaaServices'
import { Import, ImportStatusEnum } from 'services/PaaServices/types'
import { RootState } from 'Store'
import { red, green } from '@mui/material/colors'

type Props = {
  file: Import;
}
export default function FileCard({ file }: Props): ReactElement {
  const dispatch = useDispatch()
  const { deleteLoading } = useSelector((state:RootState) => state.paa)

  const renderStatus = () => {
    if (file.status === ImportStatusEnum.imported) {
      return <CheckCircleRoundedIcon sx={{ color: green[500] }} />
    }
    if (file.status === ImportStatusEnum.failed) {
      return <CancelRoundedIcon sx={{ color: red[500] }} />
    }
    return ''
  }

  function formatBytes(bytes: number, decimals: number) {
    if (bytes === 0) return '0 Bytes'
    const k = 1024
    const dm = decimals || 2
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
    const i = Math.floor(Math.log(bytes) / Math.log(k))
    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
  }

  const onClick = () => {
    dispatch(PaaServices.deleteImport(file.id))
  }

  return (
    <div style={{ position: 'relative' }}>
      <Backdrop
        open={file.status === ImportStatusEnum.pending || deleteLoading}
        className="file-backdrop"
      >
        <Loader />
      </Backdrop>
      <div className="d-flex align-items-center p-3">
        {renderStatus()}
        <div className="ml-2 d-flex flex-column text-truncate">
          <span>{file.file_name}</span>
          <span>{formatBytes(file.file_size, 1)}</span>
        </div>
        <IconButton onClick={onClick} className="ml-auto delete-button">
          <CloseIcon />
        </IconButton>
      </div>

    </div>
  )
}
