import {
  Backdrop, Dialog,
} from '@mui/material'
import Typography from '@mui/material/Typography'
import dropdown from 'assets/icons/dropdown.svg'
import terms from 'common/terms'
import FileCard from 'components/FileCard/FileCard'
import Loader from 'components/Loader'
import {
  ReactElement, useEffect, useRef,
} from 'react'
import Dropzone, { FileRejection } from 'react-dropzone'
import { useDispatch, useSelector } from 'react-redux'
import { emptyLastImport } from 'reducers/const'
import { setError } from 'reducers/feedback'
import { setLastImport, showImport } from 'reducers/paa'
import PaaServices from 'services/PaaServices'
import { Import, ImportStatusEnum } from 'services/PaaServices/types'
import { RootState } from 'Store'
import { PDIprimaryButton } from 'themes/theme'
import './UploadDialog.scss'
import checkPendingOrFailedImports from './utils'

const acceptedTypes = { 'text/plain': ['.txt'], 'text/csv': ['.csv'], 'application/zip': ['.zip'] }

export default function UploadDialog(): ReactElement {
  const dispatch = useDispatch()
  const { importList, sendImportLoading, lastImport } = useSelector((state: RootState) => state.paa)
  const fetchStatus = useRef<NodeJS.Timer | NodeJS.Timeout>()

  const onDropAccepted = (file: File[]) => {
    if (file) {
      const formData = new FormData()
      formData.append('file', file[0])
      formData.append('filename', file[0].name)
      const object = { formData }
      dispatch(PaaServices.sendImport(object.formData))
    }
  }

  const clearFetchInterval = () => {
    clearInterval(fetchStatus.current as NodeJS.Timeout)
  }

  const onDropRejected = (rejection: FileRejection[]) => {
    if (rejection[0].errors[0].code === 'file-invalid-type') {
      dispatch(setError({ code: 400, data: { message: 'Error.invalidType', messageReady: true } }))
    }
    if (rejection[0].errors[0].code === 'too-many-files') {
      dispatch(setError({ code: 400, data: { message: 'Error.tooManyFiles', messageReady: true } }))
    }
  }

  const checkStatus = (file: Import) => {
    if (file.status === ImportStatusEnum.pending) {
      fetchStatus.current = (setInterval(() => dispatch(PaaServices.getImport(importList[importList.length - 1].id)),
        2000))
      return
    }

    clearFetchInterval()
  }

  useEffect(() => {
    if (lastImport.id) {
      checkStatus(lastImport)
      return
    }
    clearFetchInterval()
  }, [lastImport])

  useEffect(() => {
    const pendingImport = importList.find(file => file.status === ImportStatusEnum.pending)
    if (pendingImport) {
      dispatch(setLastImport(pendingImport))
    }
  }, [])

  useEffect(() => () => {
    dispatch(setLastImport(emptyLastImport))
    clearFetchInterval()
  }, [])

  return (
    <Dialog className="dashboard-dialog" open>
      <Typography variant="title1" mb={3}>
        {terms.Import.title}
      </Typography>
      {importList.length > 0 ? (
        <Typography variant="body1" mb={3}>
          {terms.Import.subtitle}
        </Typography>
      ) : (
        <Typography variant="body1" mb={3}>
          {terms.Import.subtitleEmpty}
        </Typography>
      ) }

      <div className="dropzone">
        <Backdrop
          open={sendImportLoading}
          className="file-backdrop"
        >
          <Loader />
        </Backdrop>
        <Dropzone
          disabled={sendImportLoading || importList.some(imp => imp.status === ImportStatusEnum.pending)}
          onDropRejected={reject => onDropRejected(reject)}
          maxFiles={2}
          accept={acceptedTypes}
          onDropAccepted={file => onDropAccepted(file)}
        >
          {({ getRootProps, getInputProps }) => (
            <div {...getRootProps()}>
              <input {...getInputProps()} />
              <img src={dropdown} alt="" width={45} />
              <Typography variant="body1" mt={2}>
                {terms.Import.dragFile}
              </Typography>
              <Typography variant="underline">
                {terms.Import.dragFile2}
              </Typography>
            </div>
          )}
        </Dropzone>
      </div>
      <div className="file-container">
        {importList?.map(file => (
          <FileCard key={file.id} file={file} />
        ))}
      </div>
      <PDIprimaryButton
        disabled={checkPendingOrFailedImports(importList)}
        onClick={() => dispatch(showImport())}
      >
        {terms.Import.buttonImport}

      </PDIprimaryButton>
    </Dialog>
  )
}
