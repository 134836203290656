import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import { initTranslation } from '@osrdata/app_core/dist/translation'
import { SentryConfigured } from '@osrdata/app_core'
import { ThemeProvider } from '@mui/material'
import { appTheme } from './themes/theme'
import { store, persistor } from './Store'
import * as serviceWorker from './serviceWorker'

import 'variables.scss'
import App from './App'
import './index.css'
import '@sncf/bootstrap-sncf.metier/dist/bootstrap-sncf.min.css'

require('@sncf/bootstrap-sncf.metier')

SentryConfigured()
initTranslation()

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <ThemeProvider theme={appTheme}>
          <App />
        </ThemeProvider>
      </PersistGate>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root'),
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
