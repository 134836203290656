/* eslint-disable react/no-array-index-key */
import {
  Pagination,
  Paper, Table, TableBody, TableCell, TableContainer, TableRow,
} from '@mui/material'
import ToolBar from 'components/ToolBar/ToolBar'
import { ReactElement, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PaaServices from 'services/PaaServices'
import { RootState } from 'Store'
import nextId from 'react-id-generator'
import Loader from 'components/Loader'
import InfoPanel, { Severity } from 'components/InfoPanel/InfoPanel'
import terms from 'common/terms'
import DataTableHeader from './DataTableHeader'
import './DataTable.scss'

export default function DataTable(): ReactElement {
  const dispatch = useDispatch()
  const {
    activeType, importData, importDataLoading, models, ordering,
  } = useSelector((state: RootState) => state.paa)
  const [page, setPage] = useState(1)

  useEffect(() => {
    dispatch(PaaServices.getModels())
    dispatch(PaaServices.getOrdering())
    dispatch(PaaServices.getFilters())
  }, [])

  useEffect(() => {
    setPage(1)
    dispatch(PaaServices.getDataForModel({ model: activeType, page }))
  }, [activeType])

  useEffect(() => {
    dispatch(PaaServices.getDataForModel({ model: activeType, page }))
  }, [ordering])

  const onPageChange = (newPage: number) => {
    setPage(newPage)
    dispatch(PaaServices.getDataForModel({ model: activeType, page: newPage }))
  }

  const renderData = () => {
    if (importDataLoading) {
      return (
        <TableRow>
          <TableCell
            className="border-bottom-0"
            colSpan={models.find(model => model.slug === activeType)?.fields.length}
          >
            <Loader />
          </TableCell>
        </TableRow>
      )
    }

    if (importData.results.length) {
      return (
        importData.results?.map(row => (
          <TableRow
            key={nextId()}
          >
            {row.map(data => (
              <TableCell key={nextId()}>
                {data}
              </TableCell>
            ))}

          </TableRow>
        )))
    }
    return (
      <TableRow>
        <TableCell colSpan={models.find(model => model.slug === activeType)?.fields.length}>
          <InfoPanel
            title={terms.Common.noResults}
            severity={Severity.warning}
          />
        </TableCell>
      </TableRow>
    )
  }

  return (
    <>
      <ToolBar />
      <TableContainer
        component={Paper}
        className="table-container"
        sx={{ overflow: importData.results.length ? 'auto' : 'hidden' }}
      >
        <Table stickyHeader style={{ height: importDataLoading ? '100%' : 'auto' }}>
          <DataTableHeader />
          <TableBody>
            {renderData()}
          </TableBody>
        </Table>
      </TableContainer>

      <Pagination
        className="mt-1 d-flex justify-content-center"
        onChange={(_evt, newPage) => onPageChange(newPage)}
        variant="outlined"
        page={page || undefined}
        shape="rounded"
        boundaryCount={1}
        siblingCount={1}
        count={Math.ceil(importData.count / 1000) || 1}
      />
    </>
  )
}
