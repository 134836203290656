import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import PaaServices from 'services/PaaServices'
import {
  Filter,
  Import, ImportKind, Model, Order,
} from 'services/PaaServices/types'
import { emptyLastImport } from './const'
import { PaginatedImportData } from './types'

const emptyData = {
  count: 0, next: '', previous: '', results: [],
}

interface PaaState {
 importList: Import[];
 activeType: ImportKind;
 importData: PaginatedImportData;
 showImportModal: boolean;
 importsLoading: boolean;
 sendImportLoading: boolean;
 models: Model[];
 lastImport: Import;
 deleteLoading: boolean;
 showExportModal: boolean;
 exportLoading: boolean;
 exportFile: ArrayBuffer | null;
 importDataLoading: boolean;
 ordering: Order[];
 filters: Filter[];
 filterMenuOpen: boolean;
}

const initialState: PaaState = {
  importList: [],
  activeType: 'organe',
  importData: emptyData,
  showImportModal: true,
  importsLoading: true,
  sendImportLoading: false,
  models: [],
  lastImport: emptyLastImport,
  deleteLoading: false,
  showExportModal: false,
  exportLoading: false,
  exportFile: null,
  importDataLoading: true,
  ordering: [],
  filters: [],
  filterMenuOpen: false,
}

export const paaSlice = createSlice({
  name: 'paa',
  initialState,
  reducers: {
    setType: (state, action: PayloadAction<ImportKind>) => {
      state.activeType = action.payload
    },
    setLastImport: (state, action: PayloadAction<Import>) => {
      state.lastImport = action.payload
    },
    showImport: state => {
      state.showImportModal = !state.showImportModal
      state.activeType = 'organe'
    },
    showExport: state => {
      state.showExportModal = !state.showExportModal
    },
    exportFileDownloaded: state => {
      state.exportFile = null
    },
    showFilterMenu: state => {
      state.filterMenuOpen = !state.filterMenuOpen
    },
  },
  extraReducers: builder => {
    builder.addCase(PaaServices.sendImport.fulfilled, (state, { payload }) => {
      state.importList.push(payload)
      state.lastImport = payload
      state.sendImportLoading = false
    })
    builder.addCase(PaaServices.sendImport.pending, state => {
      state.sendImportLoading = true
    })
    builder.addCase(PaaServices.sendImport.rejected, state => {
      state.sendImportLoading = false
    })
    builder.addCase(PaaServices.getImports.fulfilled, (state, { payload }) => {
      state.importList = payload
      if (!payload.length) {
        state.showImportModal = true
      }
      state.importsLoading = false
    })
    builder.addCase(PaaServices.getImports.pending, state => {
      state.importsLoading = true
    })
    builder.addCase(PaaServices.getImports.rejected, state => {
      state.importsLoading = false
    })
    builder.addCase(PaaServices.getModels.fulfilled, (state, { payload }) => {
      state.models = payload
      state.activeType = payload[0].slug
    })
    builder.addCase(PaaServices.getDataForModel.fulfilled, (state, { payload }) => {
      state.importData = payload
      state.importDataLoading = false
    })
    builder.addCase(PaaServices.getDataForModel.pending, state => {
      state.importData.results = []
      state.importDataLoading = true
    })
    builder.addCase(PaaServices.getDataForModel.rejected, state => {
      state.importDataLoading = false
    })
    builder.addCase(PaaServices.getImport.fulfilled, (state, { payload }) => {
      const index = state.importList.findIndex(({ id }) => id === payload.id)
      if (state.lastImport.id === payload.id && state.lastImport.status !== payload.status) {
        state.lastImport = payload
      }
      state.importList[index] = payload
    })
    builder.addCase(PaaServices.deleteImport.rejected, state => {
      state.deleteLoading = false
    })
    builder.addCase(PaaServices.deleteImport.pending, state => {
      state.deleteLoading = true
    })
    builder.addCase(PaaServices.deleteImport.fulfilled, (state, { payload }) => {
      state.deleteLoading = false
      state.lastImport = emptyLastImport
      state.importList = state.importList.filter(({ id }) => id !== payload)
    })
    builder.addCase(PaaServices.getExport.pending, state => {
      state.exportLoading = true
    })
    builder.addCase(PaaServices.getExport.rejected, state => {
      state.exportLoading = false
    })
    builder.addCase(PaaServices.getExport.fulfilled, (state, { payload }) => {
      state.exportFile = payload
      state.exportLoading = false
    })
    builder.addCase(PaaServices.getOrdering.fulfilled, (state, { payload }) => {
      state.ordering = payload
    })
    builder.addCase(PaaServices.sendOrdering.fulfilled, (state, { payload }) => {
      const newOrder = state.ordering.filter(order => order.ordering.split('__')[0] !== payload.ordering.split('__')[0])
      newOrder.push(payload)
      state.ordering = newOrder
    })
    builder.addCase(PaaServices.sendOrdering.pending, state => {
      state.importDataLoading = true
    })
    builder.addCase(PaaServices.sendOrdering.rejected, state => {
      state.importDataLoading = false
    })
    builder.addCase(PaaServices.getFilters.fulfilled, (state, { payload }) => {
      state.filters = payload
    })
  },
})

export const {
  setType, showImport, showExport, setLastImport, exportFileDownloaded, showFilterMenu,
} = paaSlice.actions

export default paaSlice.reducer
